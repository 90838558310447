import { Injectable, Injector, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ActivatedRoute,  Router } from '@angular/router';

import * as ROUTE_LIST from '../constants/routes.constant';
import * as CONSTANT_LIST from '../constants/system.constant';

import { BaseModel } from '../models/base.model';
import { HttpService, HelperService, SharedDataService, LocalStorageService } from '../services';
import { ConfirmationDialogService } from '../services/confirmation-modal.service';
import { destroy } from '../shared/helpers/destroy';
import { PERMISSIONS_LIST } from '../constants';
import { SystemDefaults } from '../models/systemDefaults.model';
import { AuthService } from '@src/app/core/services/auth.service';
import * as ENDPOINTS from '../constants/apis-list';
import { ToastService } from '../services/toast.service';


@Injectable()
export abstract class BaseComponent implements OnDestroy {
  public apiList = ENDPOINTS;
  public routeList = ROUTE_LIST;
  public constantList = CONSTANT_LIST;
  public permissionsList = PERMISSIONS_LIST;
  public translate: TranslateService;

  public modalService: NgbModal;
  public isServer: boolean;
  public isBrowser: boolean;
  public platformId: any;

  public activatedRoute: ActivatedRoute;
  public confirmationdialogservice: ConfirmationDialogService;
  public httpService: HttpService;
  public helperService: HelperService;
  public sharedDataService: SharedDataService;
  public localStorageService: LocalStorageService;
  public authService: AuthService;
  public toast: ToastService;

  public router: Router;
  public route: ActivatedRoute;
  public innerWidth: any;
  public currentUser: any;
  public systemsDefaults: any = new SystemDefaults();
  public baseModel: any = new BaseModel();

  /**
   * the following is used to keep the page id based on for edit/detail/view screen
   */
  public pageIdParam: any = 'id';
  public pageMode: any = 'edit';

  public dataSubscription$!: Subscription;
  public updateUserSubscription$!: Subscription;
  public dropdownListSubscription$: Subscription[] = [];

  /*
   * The following is used to hold the translation message instance
   * */
  protected _unsubscribeToastrMessage$: Subject<void> = new Subject<void>();
  protected destroy$ = destroy();

  /**
   * Initializes the constructor of the class with the provided injector.
   *
   * @param {Injector} injector - The injector used to retrieve dependencies.
   */
  constructor(injector: Injector) {
    this.platformId = injector.get(PLATFORM_ID);
    this.isServer = isPlatformServer(this.platformId);
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.modalService = injector.get(NgbModal);
    this.confirmationdialogservice = injector.get(ConfirmationDialogService);
    this.translate = injector.get(TranslateService);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.modalService = injector.get(NgbModal);
    this.toast = injector.get(ToastService);
    this.httpService = injector.get(HttpService);
    this.helperService = injector.get(HelperService);
    this.sharedDataService = injector.get(SharedDataService);
    this.localStorageService = injector.get(LocalStorageService);
    this.confirmationdialogservice= injector.get(ConfirmationDialogService)
    this.authService = injector.get(AuthService);

    this.init();
  }

  /**
   * Unsubscribes from all subscriptions and cleans up resources when the component is destroyed.
   *
   * This method is called automatically by Angular when the component is being destroyed.
   * It unsubscribes from the `_unsubscribeToastrMessage$` subject, completes it, and unsubscribes from
   * any existing `dataSubscription`, `updateUserSubscription`, and `dropdownListSubscription` subscriptions.
   * If there are any subscriptions in the `dropdownListSubscription` array, it iterates over them and unsubscribes
   * from each one.
   *
   * Note: The commented line at the end of the method is not executed in the current implementation.
   *
   * @return {void} This function does not return anything.
   */
  ngOnDestroy() {
    this._unsubscribeToastrMessage$.next();
    this._unsubscribeToastrMessage$.complete();
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    if (this.updateUserSubscription$) {
      this.updateUserSubscription$.unsubscribe();
    }
    if (this.dropdownListSubscription$.length > 0) {
      this.dropdownListSubscription$.forEach((s: Subscription) => {
        s.unsubscribe();
      });
    }
  }

  init(): void {
    if (this.isBrowser && window?.innerWidth) {
      this.innerWidth = window.innerWidth;
    }
  }

  getLanguageSpecificKey(key: string): string {
    return key + '.' + this.sharedDataService.currentLanguage;
  }

  goToRoute(link: string): void {
    this.router.navigateByUrl(link);
  }

  isLogged() {
    return !!this.localStorageService.getToken();
  }

  /**
   * Logs out the user if the application is running in a browser.
   *
   * @return {any} - The result of the logout operation.
   */
  logout(): any {
    if (this.isBrowser) {
      this.authService.logout();
    }
    this.localStorageService.removeDataInLocalStorage(`${this.constantList.TOKEN}`);
    // this.goToRoute('/');
  }

  /**
   * The following check if its edit mode
   * @returns {string | null}
   */
  isEditMode(param: string = this.pageIdParam): string | null {
    return this.activatedRoute.snapshot.paramMap.get(param);
  }
}
